import React, { useEffect, useState } from 'react';

// MUI Imports
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

import { DialogTitle, FormControl, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { getUserLocations } from '../../../redux/inventoryActions';
import { useGetUserInfo } from '../../../services/user-services/queries';
import FileUploadWithPreview from './FileUploadWithPreview';
import { uploadImage } from '../../../redux/imageAction';
import { useSelector } from 'react-redux';
import { useCreateTickets } from '../../../services/other-services/mutations';
import toast from 'react-hot-toast';

export default function UpdateIssues(props) {
  const { orgLevels, locations, open, user, page, ...other } = props;

  const [userLocations, setUserLocations] = useState([]);
  const { theToken } = useSelector((state) => state.auth);

  const { data: userInfo } = useGetUserInfo();

  const [images, setImages] = useState([]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { mutate } = useCreateTickets();

  const onClose = () => {
    navigate(-1);
  };

  const formik = useFormik({
    initialValues: {
      userId: userInfo?._id,
      name: '',
      location: '',
      subject: '',
      issue: '',
      imageIds: [''],
    },
    onSubmit: async (values) => {
      const reasonImages = await uploadAttachedImage(images);
      console.log(values);
      console.log(reasonImages);

      const apiData = {
        userId: values.userId,
        name: values.name,
        location: values.location,
        subject: values.subject,
        description: values.issue,
        imageIds: reasonImages,
      };

      mutate(apiData, {
        onSuccess: () => {
          navigate(-1);
          toast.success('Ticket Created Successfully');
        },
        onError: (error) => {
          toast.error('Something went wrong while creating ticket');
        },
      });
    },
  });

  console.log(images);

  const uploadAttachedImage = async (files) => {
    if (files && files?.length > 0) {
      const imageKeys = [];

      for (const file of files) {
        const formData = new FormData();
        formData.append('image', file);

        try {
          const imageKey = await uploadImage(formData, theToken);
          if (imageKey?.data) {
            imageKeys.push(imageKey?.data);
          }
        } catch (error) {
          console.error('Error uploading image:', error);
          toast.error('Image upload error');
        }
      }
      return imageKeys;
    }
  };

  console.log(images);

  useEffect(() => {
    const fetchUserLocations = async () => {
      const results = await getUserLocations();
      const locations = results?.data?.locations || [];
      setUserLocations(locations);

      if (locations.length > 0) {
        formik.setFieldValue('location', locations[0]?._id);
      }
    };
    fetchUserLocations();
  }, []);

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': { width: '100%' },
      }}
      className='ModalPopup__dialog'
      maxWidth='md'
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: '0 !important' }}>
        <div className='ModalPopup__header'>
          <div className='ModalPopup__header__title_fs'>
            {t('raise_new_issue')}
          </div>
          <div className='ModalPopup__close_icon' onClick={onClose}>
            <i className='fa-solid fa-xmark'></i>
          </div>
        </div>
      </DialogTitle>
      <form className='ModalPopup__form_container'>
        <DialogContent
          sx={{ padding: '0 !important', borderRadius: '0.6rem !important' }}
        >
          <div className='ModalPopup__form_container'>
            <div className='ModalPopup__wrapper'>
              <form>
                <div className='ModalPopup__body'>
                  <div className='ModalPopup__form_section'>
                    <div className='Input__row three_column'>
                      <div className='Input__row two_column'>
                        <div className='Input__column'>
                          <p className='Input__label_fs'>
                            {t('email_address')}
                          </p>
                          <div className='otp_email_verify'>
                            <h1
                              style={{
                                fontSize: '12px',
                                marginTop: '-0.5rem',
                              }}
                            >
                              {userInfo?.emailId ||
                                'vaxin-helpline@statwig2.odoo.com'}
                            </h1>
                          </div>
                        </div>
                        <div className='Input__column'>
                          <p className='Input__label_fs'>{t('phone_number')}</p>
                          <div className='otp_email_verify'>
                            <h1
                              style={{
                                fontSize: '12px',
                                marginTop: '-0.5rem',
                              }}
                            >
                              +880 {userInfo?.phoneNumber}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className='Input__column'>
                        <p className='Input__label_fs'>{t('name')}</p>
                        <input
                          type='text'
                          className='mi_custom_textfield'
                          id='name'
                          name='name'
                          maxLength={50}
                          {...formik.getFieldProps('name')}
                        />
                      </div>
                      <div className='Input__column'>
                        <p className='Input__label_fs'>{t('location')}</p>
                        <FormControl
                          fullWidth
                          placeholder='Select your Organization Level'
                        >
                          <Select
                            size='small'
                            className='mui_custom_input_field'
                            id='location'
                            name='location'
                            {...formik.getFieldProps('location')}
                            disabled={userLocations.length === 1}
                          >
                            {userLocations?.map((level, index) => (
                              <MenuItem key={index} value={level?._id}>
                                {level?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className='ModalPopup__label_header'>
                      <h1 className='Input__subMessage_fs'>
                        {t('update_issues_subtitle')}
                      </h1>
                    </div>

                    <section className='update_Issue_grid_layout'>
                      <div className='Input__table'>
                        <div className='Input__column'>
                          <p className='Input__label_fs'>{t('subject')}</p>
                          <input
                            type='text'
                            className='mi_custom_textfield'
                            id='subject'
                            name='subject'
                            maxLength={50}
                            {...formik.getFieldProps('subject')}
                          />
                        </div>
                        <div className='Input__column'>
                          <p className='Input__label_fs'>{t('issue')}</p>
                          <textarea
                            rows={4}
                            className='mi_custom_textfield textarea'
                            id='issue'
                            name='issue'
                            placeholder={t('describe_issue') + '...'}
                            {...formik.getFieldProps('issue')}
                          ></textarea>
                        </div>
                      </div>

                      <FileUploadWithPreview setImages={setImages} />
                    </section>
                  </div>
                </div>
                <div className='UpdateProfile__form_action'>
                  <button
                    className='mi_btn mi_btn_medium mi_btn_primary'
                    onClick={formik.handleSubmit}
                  >
                    <span>{t('create_issue')}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </form>
    </Dialog>
  );
}
