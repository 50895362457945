import React, { useState } from 'react';

const FileUploadWithPreview = ({ setImages }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [previewImage, setPreviewImages] = useState([]);

  const handleFileChange = (e) => {
    setImages(e.currentTarget.files);
    const files = Array.from(e.target.files);
    const imageFiles = files.map((file) => URL.createObjectURL(file));
    setPreviewImages(imageFiles);
    setCurrentIndex(0);
  };

  const handleNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % previewImage.length);
  };

  const handlePrevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? previewImage.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className='Input__table'>
      <div className='Input__column'>
        <p className='Input__label_fs'>Attachments</p>
        <input
          type='file'
          className='mi_custom_textfield textarea'
          id='attachments'
          name='attachments'
          multiple
          onChange={handleFileChange}
          accept='image/*'
        />
        <span className='Input__error_fs d_none'></span>
      </div>

      <div className='Attachment_preview'>
        <div className='Attachment_preview__wrapper'>
          {previewImage.length > 0 && (
            <div className='slider'>
              <img
                src={previewImage[currentIndex]}
                alt={`Preview ${currentIndex + 1}`}
                className='preview-image'
              />
              {previewImage.length > 1 && (
                <div
                  onClick={handlePrevImage}
                  className='preview_slide_button left_button'
                >
                  <i className='fa-solid fa-chevron-left'></i>
                </div>
              )}
              {previewImage.length > 1 && (
                <div
                  onClick={handleNextImage}
                  className='preview_slide_button right_button'
                >
                  <i className='fa-solid fa-chevron-right'></i>
                </div>
              )}
            </div>
          )}
          {previewImage.length === 0 && (
            <div className='slider'>
              <p>No files uploaded.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUploadWithPreview;
