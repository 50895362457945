import React from 'react';
import BarGraph from '../InvGraphs/BarGraph';
import LineGraph from '../InvGraphs/LineGraph';
import AreaGraph from '../InvGraphs/AreaGraph';

export default function GraphContainer({ graph, graphHeight }) {
  const [graphType, setGraphType] = React.useState('Bars');

  return (
    <>
      {graphType === 'Bars' ? (
        <BarGraph
          key={graph.key}
          data={graph.data}
          xAxisKey={graph.xAxisKey}
          title={graph.title}
          bars={graph.bars}
          height={graphHeight}
          chartConfig={graph.chartConfig}
          graphType={graphType}
          setGraphType={setGraphType}
        />
      ) : graphType === 'Lines' ? (
        <LineGraph
          key={graph.key}
          data={graph.data}
          xAxisKey={graph.xAxisKey}
          title={graph.title}
          lines={graph.lines}
          height={graphHeight}
          chartConfig={graph.chartConfig}
          graphType={graphType}
          setGraphType={setGraphType}
        />
      ) : (
        <AreaGraph
          key={graph.key}
          data={graph.data}
          xAxisKey={graph.xAxisKey}
          title={graph.title}
          areas={graph.lines}
          height={graphHeight}
          chartConfig={graph.chartConfig}
          graphType={graphType}
          setGraphType={setGraphType}
        />
      )}
    </>
  );
}
