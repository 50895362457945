import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useTranslation } from 'react-i18next';
import ConfigureTable from '@/shared/configureTable/ConfigureTable';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

export default function TabContainer({
  currentTab,
  setCurrentTab,
  open,
  anchorEl,
  handleClose,
  columnData,
  setColumnData,
  columns,
  selectedColumns,
  handleColumnToggle,
  handleResetColumns,
  handleClick,
  handlePrint,
  title,
  view,
  setView,
  graphHeight,
  setGraphHeight,
  type,
}) {
  const { t } = useTranslation();
  return (
    <section className='Graph_tab__container'>
      <div className='w-full flex items-center justify-between flex-wrap gap-2 pb-4'>
        <Tabs
          value={currentTab}
          onValueChange={setCurrentTab}
          defaultValue='account'
          className='w-[500px]'
        >
          <TabsList>
            <TabsTrigger value='inventory' className='px-6'>
              {t('inventory')}
            </TabsTrigger>
            <TabsTrigger value='orders' className='px-6'>
              {t('orders')}
            </TabsTrigger>
            <TabsTrigger value='shipments' className='px-6'>
              {t('shipments')}
            </TabsTrigger>
            <TabsTrigger value='lastmile' className='px-6'>
              {t('last_mile')}
            </TabsTrigger>
            <TabsTrigger value='coldchain' className='px-6'>
              {t('cold_chain')}
            </TabsTrigger>
          </TabsList>
        </Tabs>

        {type === 'graph' && (
          <div className='graph__left'>
            <div className='graph_column'>
              {/* <span>Graph Filters:</span> */}
              <button
                className='mi_btn mi_btn_medium mi_btn_outline'
                onClick={handleClick}
              >
                <span>Default: Show all</span>
              </button>
              <ConfigureTable
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                value={columnData}
                setValue={setColumnData}
                columns={columns}
                selectedColumns={selectedColumns}
                onColumnToggle={handleColumnToggle}
                handleResetColumns={handleResetColumns}
                title={title}
              />
            </div>

            <Select
              className='bg-white'
              value={view}
              onValueChange={(value) => setView(value)}
            >
              <SelectTrigger className='w-[110px]'>
                <SelectValue placeholder='Graph Type' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='grid'>Grid View</SelectItem>
                <SelectItem value='full'>Full View</SelectItem>
              </SelectContent>
            </Select>

            {/* <Select
              value={graphHeight}
              onValueChange={(value) => setGraphHeight(value)}
            >
              <SelectTrigger className='w-[100px]'>
                <SelectValue placeholder='Graph Type' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={300}>Small</SelectItem>
                <SelectItem value={400}>Normal</SelectItem>
                <SelectItem value={500}>Large</SelectItem>
              </SelectContent>
            </Select> */}
            <div className='graph_column'>
              {/* <span>Graph Exports:</span> */}
              <button
                className='mi_btn mi_btn_medium mi_btn_outline'
                onClick={handlePrint}
              >
                <span>Download</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
