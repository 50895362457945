const SERVER_URL = import.meta.env.VITE_API_URL || 'https://vaxin.dghs.gov.bd/';
const LOCAL_USER = 'http://localhost:4000';
const LOCAL_INVENTORY = 'http://localhost:4001';
const LOCAL_SHIPMENT = 'http://localhost:4002';
const LOCAL_NOTIFICATION = 'http://localhost:4003';
const LOCAL_ROUTING = 'http://localhost:5000';

const localConf = {
  getOrganizationLevelsUrl: `${LOCAL_USER}/api/user-service/v1/orgLevels`,
  registerUrl: `${LOCAL_USER}/api/user-service/v1/register`,
  sendOtpUrl: `${LOCAL_USER}/api/user-service/v1/send-otp`,
  verifyOtpUrl: `${LOCAL_USER}/api/user-service/v1/verify-otp`,
  updatePwdUrl: `${LOCAL_USER}/api/user-service/v1/password`,
  logoutUrl: `${LOCAL_USER}/api/user-service/v1/logout`,
  loginUrl: `${LOCAL_USER}/api/user-service/v1/login`,
  loginHrisUrl: `${LOCAL_USER}/api/user-service/v1/sign-in-hris`,
  adminUsers: `${LOCAL_USER}/api/user-service/v1/admin/users`,
  adminDashboardSummaryUrl: `${LOCAL_USER}/api/user-service/v1/admin/users/summary`,
  adminLocationsUrl: `${LOCAL_USER}/api/user-service/v1/admin/locations`,
  adminOrgLevelsUrl: `${LOCAL_USER}/api/user-service/v1/admin/orgLevels`,
  addProductUrl: `${LOCAL_USER}/api/user-service/v1/admin/products`,
  rolesUrl: `${LOCAL_USER}/api/user-service/v1/admin/roles`,
  permissionsUrl: `${LOCAL_USER}/api/user-service/v1/admin/permissions`,
  productsUrl: `${LOCAL_USER}/api/user-service/v1/products`,
  countriesUrl: `${LOCAL_USER}/api/user-service/v1/countries`,
  getAirportInfo: `${LOCAL_USER}/api/user-service/v1/airports`,
  productTypesUrl: `${LOCAL_USER}/api/user-service/v1/productTypes`,
  manufacturersUrl: `${LOCAL_USER}/api/user-service/v1/manufacturers`,
  userRolesUrl: `${LOCAL_USER}/api/user-service/v1/roles`,
  userInfoUrl: `${LOCAL_USER}/api/user-service/v1/users/info`,
  userLocationsUrl: `${LOCAL_USER}/api/user-service/v1/users/locations`,
  userLocationInfoUrl: `${LOCAL_USER}/api/user-service/v1/users/locations/info`,
  childLocationsUrl: `${LOCAL_USER}/api/user-service/v1/users/child-locations`,
  childOrgLevelUrl: `${LOCAL_USER}/api/user-service/v1/users/child-locations/orgLevel`,
  locationUrl: `${LOCAL_USER}/api/user-service/v1/locations`,
  thresholdUrl: `${LOCAL_USER}/api/user-service/v1/admin/threshold`,
  changeLocationsUrl: `${LOCAL_USER}/api/user-service/v1/users/locations/change`,
  inventoryUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory/events`,
  inventoryAnalyticsUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory/analytics`,
  addInventoryUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory`,
  currentInventoryStockUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory`,
  outOfStockInventoryUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory/outOfStock`,
  getAllInventoryProducts: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory/products`,
  nearExpiryInventoryUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory/nearExpiry`,
  expiredInventoryUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory/expired`,
  rejectedInventoryUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory/rejected`,
  wastedUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory/wasted`,
  quarantineUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory/quarantine`,
  disposedUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory/disposed`,
  batchesOfProductUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/batches/`,
  inventoryProductValue: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory/value`,
  updateVvmStatusUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory/vvm`,
  createVarUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/vars`,
  getVarListUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/vars`,
  recallUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/recall`,
  adjustmentUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/adjustments`,
  parUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/pars`,
  coldChainUrl: `${LOCAL_INVENTORY}/api/coldchain-service/v1/assets/jobs`,
  temperatureUrl: `${LOCAL_INVENTORY}/api/coldchain-service/v1/assets/temperature`,
  getEquipmentList: `${LOCAL_INVENTORY}/api/coldchain-service/v1/assets`,
  getInventories: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventories`,
  beneficiariesUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/beneficiaries`,
  beneficiariesAnalyticsUrl: `${LOCAL_INVENTORY}/api/inventory-service/v1/beneficiaries/analytics`,
  unitUtilized: `${LOCAL_INVENTORY}/api/inventory-service/v1/inventory/utilized`,
  inBoundOrdersUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/orders/inbound`,
  outBoundOrdersUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/orders/outbound`,
  inBoundOrdersAnalyticsUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/orders/analytics/inbound`,
  outBoundOrdersAnalyticsUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/orders/analytics/outbound`,
  orderDetailsUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/orders/info/`,
  respondOrderUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/orders/respond`,
  createOrderUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/orders`,
  cancelOrderUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/orders`,
  outboundShipmentsUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/shipments/outbound`,
  outboundShipmentAnalyticsUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/shipments/analytics/outbound`,
  inboundShipmentsUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/shipments/inbound`,
  inboundShipmentAnalyticsUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/shipments/analytics/inbound`,
  createShipmentUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/shipments`,
  transitShipmentUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/shipments/transitNos`,
  shipmentDetailsUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/shipments/info/`,
  shipmentReceiveUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/shipments/receive`,
  campaignUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/campaigns`,
  tripsUrl: `${LOCAL_SHIPMENT}/api/shipment-service/v1/trips`,
  notificationUrl: `${LOCAL_NOTIFICATION}/api/notification-service/v1/notifications`,
  ticketUrl: `${LOCAL_NOTIFICATION}/api/notification-service/v1/tickets`,
  alertsUrl: `${LOCAL_USER}/api/user-service/v1/alerts`,
  imagesUrl: `${LOCAL_USER}/api/user-service/v1/images`,
  chatUrl: `${LOCAL_USER}/api/chatbot-service/v1/messages`,
  needForCastUrl: `${LOCAL_USER}/api/shipment-service/v1/needForeCast`,
  routingUrl: `${LOCAL_ROUTING}/api/routing-service/route/v1/driving`,

  // V2 API
  inventoryUrlv2: `${LOCAL_INVENTORY}/api/inventory-service/v2/inventory`,
  beneficiariesUrlv2: `${LOCAL_INVENTORY}/api/inventory-service/v2/beneficiaries`,
  assetsUrlv2: `${LOCAL_INVENTORY}/api/inventory-service/v2/assets`,
};

const prodConf = {
  getOrganizationLevelsUrl: `${SERVER_URL}/api/user-service/v1/orgLevels`,
  registerUrl: `${SERVER_URL}/api/user-service/v1/register`,
  sendOtpUrl: `${SERVER_URL}/api/user-service/v1/send-otp`,
  verifyOtpUrl: `${SERVER_URL}/api/user-service/v1/verify-otp`,
  updatePwdUrl: `${SERVER_URL}/api/user-service/v1/password`,
  logoutUrl: `${SERVER_URL}/api/user-service/v1/logout`,
  loginUrl: `${SERVER_URL}/api/user-service/v1/login`,
  loginHrisUrl: `${SERVER_URL}/api/user-service/v1/sign-in-hris`,
  adminUsers: `${SERVER_URL}/api/user-service/v1/admin/users`,
  adminDashboardSummaryUrl: `${SERVER_URL}/api/user-service/v1/admin/users/summary`,
  adminLocationsUrl: `${SERVER_URL}/api/user-service/v1/admin/locations`,
  adminOrgLevelsUrl: `${SERVER_URL}/api/user-service/v1/admin/orgLevels`,
  addProductUrl: `${SERVER_URL}/api/user-service/v1/admin/products`,
  rolesUrl: `${SERVER_URL}/api/user-service/v1/admin/roles`,
  permissionsUrl: `${SERVER_URL}/api/user-service/v1/admin/permissions`,
  productsUrl: `${SERVER_URL}/api/user-service/v1/products`,
  countriesUrl: `${SERVER_URL}/api/user-service/v1/countries`,
  getAirportInfo: `${SERVER_URL}/api/user-service/v1/airports`,
  productTypesUrl: `${SERVER_URL}/api/user-service/v1/productTypes`,
  manufacturersUrl: `${SERVER_URL}/api/user-service/v1/manufacturers`,
  userRolesUrl: `${SERVER_URL}/api/user-service/v1/roles`,
  userInfoUrl: `${SERVER_URL}/api/user-service/v1/users/info`,
  thresholdUrl: `${SERVER_URL}/api/user-service/v1/admin/threshold`,
  userLocationsUrl: `${SERVER_URL}/api/user-service/v1/users/locations`,
  userLocationInfoUrl: `${SERVER_URL}/api/user-service/v1/users/locations/info`,
  childLocationsUrl: `${SERVER_URL}/api/user-service/v1/users/child-locations`,
  childOrgLevelUrl: `${SERVER_URL}/api/user-service/v1/users/child-locations/orgLevels`,
  locationUrl: `${SERVER_URL}/api/user-service/v1/locations`,
  changeLocationsUrl: `${SERVER_URL}/api/user-service/v1/users/locations/change`,
  inventoryUrl: `${SERVER_URL}/api/inventory-service/v1/inventory/events`,
  inventoryAnalyticsUrl: `${SERVER_URL}/api/inventory-service/v1/inventory/analytics`,
  addInventoryUrl: `${SERVER_URL}/api/inventory-service/v1/inventory`,
  currentInventoryStockUrl: `${SERVER_URL}/api/inventory-service/v1/inventory`,
  outOfStockInventoryUrl: `${SERVER_URL}/api/inventory-service/v1/inventory/outOfStock`,
  getAllInventoryProducts: `${SERVER_URL}/api/inventory-service/v1/inventory/products`,
  nearExpiryInventoryUrl: `${SERVER_URL}/api/inventory-service/v1/inventory/nearExpiry`,
  expiredInventoryUrl: `${SERVER_URL}/api/inventory-service/v1/inventory/expired`,
  rejectedInventoryUrl: `${SERVER_URL}/api/inventory-service/v1/inventory/rejected`,
  wastedUrl: `${SERVER_URL}/api/inventory-service/v1/inventory/wasted`,
  quarantineUrl: `${SERVER_URL}/api/inventory-service/v1/inventory/quarantine`,
  disposedUrl: `${SERVER_URL}/api/inventory-service/v1/inventory/disposed`,
  batchesOfProductUrl: `${SERVER_URL}/api/inventory-service/v1/batches/`,
  inventoryProductValue: `${SERVER_URL}/api/inventory-service/v1/inventory/value`,
  updateVvmStatusUrl: `${SERVER_URL}/api/inventory-service/v1/inventory/vvm`,
  createVarUrl: `${SERVER_URL}/api/inventory-service/v1/vars`,
  getVarListUrl: `${SERVER_URL}/api/inventory-service/v1/vars`,
  recallUrl: `${SERVER_URL}/api/inventory-service/v1/recalls`,
  adjustmentUrl: `${SERVER_URL}/api/inventory-service/v1/adjustments`,
  parUrl: `${SERVER_URL}/api/inventory-service/v1/pars`,
  coldChainUrl: `${SERVER_URL}/api/coldchain-service/v1/jobs`,
  temperatureUrl: `${SERVER_URL}/api/coldchain-service/v1/temperatures`,
  getEquipmentList: `${SERVER_URL}/api/coldchain-service/v1/assets`,
  assetTransferUrl: `${SERVER_URL}/api/coldchain-service/v1/transfers`,
  getInventories: `${SERVER_URL}/api/inventory-service/v1/inventories`,
  beneficiariesUrl: `${SERVER_URL}/api/inventory-service/v1/beneficiaries`,
  beneficiariesAnalyticsUrl: `${SERVER_URL}/api/inventory-service/v1/beneficiaries/analytics`,
  unitUtilized: `${SERVER_URL}/api/inventory-service/v1/inventory/utilized`,
  inBoundOrdersUrl: `${SERVER_URL}/api/shipment-service/v1/orders/inbound`,
  outBoundOrdersUrl: `${SERVER_URL}/api/shipment-service/v1/orders/outbound`,
  inBoundOrdersAnalyticsUrl: `${SERVER_URL}/api/shipment-service/v1/orders/analytics/inbound`,
  outBoundOrdersAnalyticsUrl: `${SERVER_URL}/api/shipment-service/v1/orders/analytics/outbound`,
  orderDetailsUrl: `${SERVER_URL}/api/shipment-service/v1/orders/info/`,
  respondOrderUrl: `${SERVER_URL}/api/shipment-service/v1/orders/respond`,
  createOrderUrl: `${SERVER_URL}/api/shipment-service/v1/orders`,
  cancelOrderUrl: `${SERVER_URL}/api/shipment-service/v1/orders`,
  outboundShipmentsUrl: `${SERVER_URL}/api/shipment-service/v1/shipments/outbound`,
  outboundShipmentAnalyticsUrl: `${SERVER_URL}/api/shipment-service/v1/shipments/analytics/outbound`,
  inboundShipmentsUrl: `${SERVER_URL}/api/shipment-service/v1/shipments/inbound`,
  inboundShipmentAnalyticsUrl: `${SERVER_URL}/api/shipment-service/v1/shipments/analytics/inbound`,
  createShipmentUrl: `${SERVER_URL}/api/shipment-service/v1/shipments`,
  transitShipmentUrl: `${SERVER_URL}/api/shipment-service/v1/shipments/transitNos`,
  shipmentDetailsUrl: `${SERVER_URL}/api/shipment-service/v1/shipments/info/`,
  shipmentReceiveUrl: `${SERVER_URL}/api/shipment-service/v1/shipments/receive`,
  campaignUrl: `${SERVER_URL}/api/shipment-service/v1/campaigns`,
  tripsUrl: `${SERVER_URL}/api/shipment-service/v1/trips`,
  notificationUrl: `${SERVER_URL}/api/notification-service/v1/notifications`,
  ticketUrl: `${SERVER_URL}/api/notification-service/v1/tickets`,
  alertsUrl: `${SERVER_URL}/api/user-service/v1/alerts`,
  imagesUrl: `${SERVER_URL}/api/user-service/v1/images`,
  chatUrl: `${SERVER_URL}/api/chatbot-service/v1/messages`,
  needForCastUrl: `${SERVER_URL}/api/shipment-service/v1/needForeCast`,
  routingUrl: `${SERVER_URL}/api/routing-service/route/v1/driving`,

  // V2 API
  inventoryUrlv2: `${SERVER_URL}/api/inventory-service/v2/inventory`,
  beneficiariesUrlv2: `${SERVER_URL}/api/inventory-service/v2/beneficiaries`,
  assetsUrlv2: `${SERVER_URL}/api/inventory-service/v2/assets`,
  supplyUrlv2: `${SERVER_URL}/api/shipment-service/v2/shipments`,
  indentUrlv2: `${SERVER_URL}/api/shipment-service/v2/orders`,
};

export const API_URL = prodConf || localConf;

export const APP_ENVIRONMENT = SERVER_URL.includes('test') ? 'test' : 'epi';
