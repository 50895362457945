import React from 'react';
import ConfigureTable from '../../../../shared/configureTable/ConfigureTable';
import { FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import { useGetAssetsGraph } from '../../../../services/coldchain-services/queries';
import { useSearchParams } from 'react-router-dom';
import PieGraph from './InvGraphs/PieChart';
import TabContainer from './containers/TabContainer';
import CircularLoader from '@/shared/Loader/CircularLoader';

export default function DataColdChainGraph({
  handlePrint,
  currentTab,
  setCurrentTab,
  type,
}) {
  // States
  const [view, setView] = React.useState('grid');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [graphType, setGraphType] = React.useState('Bars');
  const [graphHeight, setGraphHeight] = React.useState(250);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  // Columns configuration based on provided data
  const columns = [
    { key: 'yearOfInstallation', title: 'Year of Installation' },
    { key: 'model', title: 'Model' },
    { key: 'type', title: 'Type' },
    { key: 'manufacturer', title: 'Manufacturer' },
    { key: 'powerSource', title: 'Power Source' },
    { key: 'pqsStatus', title: 'PQS Status' },
    { key: 'status', title: 'Status' },
    { key: 'capacity', title: 'Capacity' },
  ];

  const initialColumns = columns.reduce((acc, column) => {
    acc[column.key] = true;
    return acc;
  }, {});

  const [selectedColumns, setSelectedColumns] = React.useState(initialColumns);
  const [columnData, setColumnData] = React.useState([]);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const pieChartDataMap = {
    yearOfInstallation: 'Year of Installation',
    model: 'Model',
    type: 'Type',
    manufacturer: 'Manufacturer',
    powerSource: 'Power Source',
    pqsStatus: 'PQS Status',
    status: 'Status',
    capacity: 'Capacity',
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const apiBody = {
    locations: searchParams.get('locations').split(','),
  };

  const { data, isLoading, isError } = useGetAssetsGraph(apiBody);

  if (isLoading) return <>Loading...</>;

  if (isError) return <div>Error: {isError.message}</div>;

  return (
    <div>
      <TabContainer
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        columnData={columnData}
        setColumnData={setColumnData}
        columns={columns}
        selectedColumns={selectedColumns}
        handleColumnToggle={handleColumnToggle}
        handleResetColumns={handleResetColumns}
        handleClick={handleClick}
        handlePrint={handlePrint}
        title={'Filter the Graph'}
        view={view}
        setView={setView}
        graphHeight={graphHeight}
        setGraphHeight={setGraphHeight}
        type={type}
      />

      <div className={`Graph_list ${view}`}>
        {Object.keys(pieChartDataMap).map(
          (key) =>
            selectedColumns[key] && (
              <PieGraph
                key={key}
                data={data[key]}
                title={pieChartDataMap[key]}
                height={graphHeight}
              />
            )
        )}
      </div>
    </div>
  );
}
