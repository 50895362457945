import React, { useEffect, useMemo } from 'react';
import { Formik } from 'formik';

// MUI Imports
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

import FormControl from '@mui/material/FormControl';
import {
  Autocomplete,
  Chip,
  DialogActions,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import AddLocation from '../addLocation/AddLocation';
import {
  adminLocations,
  getAdminOrgLevels,
  getLocations,
} from '../../../redux/userActions';
import { useDispatch } from 'react-redux';
import { modifyUsers } from '../../../redux/userActions';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import LocationList from './LocationList';

export default function UpdateUser(props) {
  const {
    onClose,
    open,
    roles,
    analyticUpdated,
    setAnalyticUpdated,
    user,
    ...other
  } = props;

  const [locations, setLocations] = React.useState([]);

  const [isNewLocation, setIsNewLocation] = React.useState(false);

  // Local States
  const [levelsList, setLevelsList] = React.useState([]);
  const [orgLevel, setOrgLevel] = React.useState();
  const [locationList, setLocationList] = React.useState([]);
  const [selectedLocation, setSelectedLocation] = React.useState();

  useEffect(() => {
    async function fetchData(level) {
      if (level) {
        const res = await getLocations(level);
        setLocationList(res?.data);
      }
    }
    fetchData(orgLevel);
  }, [orgLevel]);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchOrgLevels = async () => {
      const res = await getAdminOrgLevels();
      setLevelsList(res.data);
    };
    fetchOrgLevels();
  }, []);

  React.useEffect(() => {
    let ChildLevels = null;
    switch (user?.orgLevel) {
      case 'EPI':
        ChildLevels = 'EPI';
        break;
      case 'DISTRICT':
        ChildLevels = 'DISTRICT';
        break;
      case 'UPAZILA':
        ChildLevels = 'UPAZILA';
        break;
      case 'MUNICIPALITY':
        ChildLevels = 'MUNICIPALITY';
        break;
      case 'CITY_CORPORATION':
        ChildLevels = 'CITY_CORPORATION';
        break;
      case 'ZONE':
        // TODO: CHECK THIS
        ChildLevels = 'ZONE';
        break;
      case 'WARD':
        ChildLevels = 'WARD';
        break;
      case 'OUTREACH_CENTRE':
        ChildLevels = 'OUTREACH_CENTRE';
        break;
      default:
        ChildLevels = null;
        break;
    }
    fetchData(ChildLevels);
  }, [user?.orgLevel]);

  const fetchData = async (level) => {
    if (level) {
      const res = await adminLocations(level);
      setLocations(res?.data?.data);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.role) {
      errors.role = t('required');
    }
    if (!values.location) {
      errors.location = t('required');
    }

    return errors;
  };

  const initialValues = {
    role: user?.role?._id || '',
    location: user?.locations || '',
  };

  const handleFormSubmit = async (values) => {
    const updatedBody = {
      userId: user._id,
      status: user.accountStatus,
      roleId: values.role,
      locationIds: values.location,
    };

    const res = await modifyUsers(updatedBody);
    if (res.success) {
      toast.success(t('user_updated'));
      setAnalyticUpdated((prev) => !prev);
      onClose();
    } else {
      toast.error(t('something_went_wrong'));
      onClose();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        sx={{
          '& .MuiDialog-paper': { width: '100%' },
        }}
        className='ModalPopup__dialog'
        maxWidth='sm'
        open={open}
        {...other}
      >
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle sx={{ padding: '0 !important' }}>
                <div className='ModalPopup__header'>
                  <div className='ModalPopup__header__title_fs'>
                    {t('update_user_title')}
                  </div>
                  <div
                    className='ModalPopup__close_icon'
                    onClick={() => {
                      handleReset();
                      onClose();
                    }}
                  >
                    <i className='fa-solid fa-xmark'></i>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent
                sx={{
                  padding: '0 !important',
                  borderRadius: '0.6rem !important',
                }}
              >
                <div className='ModalPopup__form_container'>
                  <div className='ModalPopup__wrapper'>
                    <div className='ModalPopup__body'>
                      <div className='ModalPopup__form_section'>
                        <div className='ModalPopup__label_header'>
                          <h1 className='Input__label_fs'>
                            {t('update_user_subtitle')}
                          </h1>
                        </div>

                        <div className='Input__row two_column'>
                          <div className='Input__column'>
                            <p className='Input__label_fs'>
                              {user?.firstName &&
                                user?.lastName &&
                                t('user_name')}
                            </p>
                            <div className='otp_email_verify'>
                              <h1
                                style={{
                                  fontSize: '12px',
                                  marginTop: '-0.5rem',
                                }}
                              >
                                {user?.firstName} {user?.lastName}
                              </h1>
                            </div>
                          </div>
                          <div className='Input__column'>
                            <p className='Input__label_fs'>
                              {user?.emailId && t('email_address')}{' '}
                              {user?.phoneNumber &&
                                !user?.emailId &&
                                t('phone_number')}
                            </p>
                            <div className='otp_email_verify'>
                              <h1
                                style={{
                                  fontSize: '12px',
                                  marginTop: '-0.5rem',
                                }}
                              >
                                {user?.emailId}{' '}
                                {user?.phoneNumber &&
                                  !user?.emailId &&
                                  user?.phoneNumber}
                              </h1>
                            </div>
                          </div>
                        </div>

                        <div className='Input__table'>
                          <div className='Input__column'>
                            <p className='Input__label_fs'>
                              {t('user_role')}
                              <b>*</b>
                            </p>

                            <FormControl fullWidth>
                              <Select
                                className='mui_custom_input_field'
                                size='small'
                                id='role'
                                name='role'
                                onChange={handleChange}
                                value={values.role}
                              >
                                {roles?.map((level, index) => (
                                  <MenuItem key={index} value={level?._id}>
                                    {level?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.role && touched.role && (
                                <span className='Input__error_fs'>
                                  {errors.role}
                                </span>
                              )}
                            </FormControl>
                          </div>
                        </div>
                        <div className='Edit_user_location_wrapper'>
                          <div className='ModalPopup__label_header'>
                            <h1 className='ModalPopup__form_section_heading_fs'>
                              {t('add_user_subtitle2')}
                            </h1>
                            <button
                              className='mi_btn mi_btn_link'
                              onClick={(e) => {
                                e.preventDefault();
                                setIsNewLocation(true);
                              }}
                            >
                              <i className='fa-solid fa-plus'></i>
                              <span>{t('add') + ' ' + t('location')}</span>
                            </button>
                          </div>

                          <div className='location_list'>
                            {values?.location?.map((loc) => (
                              <LocationList
                                values={values}
                                setFieldValue={setFieldValue}
                                data={loc}
                              />
                            ))}
                          </div>

                          {isNewLocation && (
                            <div>
                              <p className='Input__note_fs'>
                                {t(
                                  'Please select org level & loction assign new location to user !'
                                )}
                              </p>
                              <div className='Edit_user_location_container'>
                                <div className='Input__column'>
                                  <FormControl fullWidth>
                                    <Select
                                      size='small'
                                      className='mui_custom_input_field'
                                      onChange={(e) => {
                                        setOrgLevel(e.target.value);
                                        setSelectedLocation();
                                      }}
                                    >
                                      {levelsList?.map((item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item?.value}
                                        >
                                          {item?.value}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>

                                <div className='Input__column'>
                                  <FormControl fullWidth>
                                    <Select
                                      size='small'
                                      className='mui_custom_input_field'
                                      onChange={(e) => {
                                        setSelectedLocation(e.target.value);
                                      }}
                                    >
                                      {locationList?.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                          {item?.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div
                                  className='mi_btn mi_btn_small mi_btn_secondary'
                                  onClick={() => {
                                    setFieldValue(
                                      'location',
                                      selectedLocation
                                        ? [...values.location, selectedLocation]
                                        : [...values.location]
                                    );
                                    setOrgLevel();
                                    setSelectedLocation();
                                  }}
                                >
                                  Add Location
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <div className='ModalPopup__action_wrapper'>
                  <div className='ModalPopup_actions'>
                    <button
                      type='submit'
                      className='mi_btn mi_btn_medium mi_btn_primary'
                    >
                      <span> {t('save')}</span>
                    </button>
                  </div>
                </div>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
