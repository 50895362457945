import React from 'react';
import { doseUtilizedData, stockAvailabilityData, stockOutData } from '../data';
import StockGraph from './InvGraphs/LineGraph';
import ConfigureTable from '../../../../shared/configureTable/ConfigureTable';
import { FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import BarGraph from './InvGraphs/BarGraph';
import { useSearchParams } from 'react-router-dom';
import { useGetBeneficiariesGraph } from '../../../../services/lastmile-services/queries';
import {
  createBarsAndLinesConfig,
  stringToColorHex,
} from '../../../../shared/utils/utils';
import TabContainer from './containers/TabContainer';
import GraphContainer from './containers/GraphContainer';
import CircularLoader from '@/shared/Loader/CircularLoader';

export default function DataLastMileGraph({
  handlePrint,
  currentTab,
  setCurrentTab,
  type,
}) {
  // States
  const [view, setView] = React.useState('grid');
  const [graphType, setGraphType] = React.useState('Bars');
  const [graphHeight, setGraphHeight] = React.useState(250);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const columns = [
    { key: 'doseUtilized', title: 'Dose Utilized' },
    { key: 'noOfBeneficiaries', title: 'No of Beneficiaries' },
  ];

  const initialColumns = {
    doseUtilized: true,
    noOfBeneficiaries: true,
  };

  const [selectedColumns, setSelectedColumns] = React.useState(initialColumns);
  const [columnData, setColumnData] = React.useState([]);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const filteredColumns = columns.filter(
    (column) => selectedColumns[column.key]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const apiBody = {
    locations: searchParams.get('locations').split(','),
    fromDate: searchParams.get('fromDate') || null,
    toDate: searchParams.get('toDate') || null,
  };

  const { data, isLoading, isError } = useGetBeneficiariesGraph(apiBody);

  if (isLoading) return <>Loading...</>;

  if (isError) return <div>Error: {isError.message}</div>;

  // Extract dynamic location keys from API data
  const locationKeys =
    data.length > 0 ? Object.keys(data[0]).filter((key) => key !== 'date') : [];

  // Mapping API data to graph structure with dynamic keys
  const mappedData = data.map((item) => {
    const mappedItem = { date: item.date };
    locationKeys.forEach((key) => {
      mappedItem[key] = item[key];
    });
    return mappedItem;
  });

  const graphs = [
    {
      key: 'doseUtilized',
      data: [],
      xAxisKey: 'date',
      title: 'Dose Utilized Over Time',
      ...createBarsAndLinesConfig([]),
    },
    {
      key: 'noOfBeneficiaries',
      data: mappedData,
      xAxisKey: 'date',
      title: 'No. of Beneficiaries Over Time',
      ...createBarsAndLinesConfig(mappedData),
    },
  ];

  return (
    <div>
      <TabContainer
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        columnData={columnData}
        setColumnData={setColumnData}
        columns={columns}
        selectedColumns={selectedColumns}
        handleColumnToggle={handleColumnToggle}
        handleResetColumns={handleResetColumns}
        handleClick={handleClick}
        handlePrint={handlePrint}
        title={'Filter the Graph'}
        view={view}
        setView={setView}
        graphHeight={graphHeight}
        setGraphHeight={setGraphHeight}
        type={type}
      />

      <div className={`Graph_list ${view}`}>
        {graphs.map((graph) =>
          filteredColumns.some((col) => col.key === graph.key) ? (
            <GraphContainer graph={graph} graphHeight={graphHeight} />
          ) : null
        )}
      </div>
    </div>
  );
}
