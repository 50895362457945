import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import FCW300 from '/assetImages/FCW 300.png';
import MF300 from '/assetImages/MF 300.png';
import MF314 from '/assetImages/MF 314.png';
import MK144 from '/assetImages/MK 144.png';
import MK302 from '/assetImages/MK 302.png';
import MK304 from '/assetImages/MK 304.png';
import SB300 from '/assetImages/SB 300.png';
import TCW2000DC from '/assetImages/TCW 2000 DC.png';
import TCW3000AC from '/assetImages/TCW 3000 AC.png';
import TCW3000SDD from '/assetImages/TCW 3000 SDD.png';
import WICR from '/assetImages/WICR.png';
import NOIMAGE from '/assetImages/NO IMAGE.jpg';

// Date & Time Format
dayjs.extend(utc);
dayjs.extend(relativeTime);

export function timeAgo(date) {
  const newDate =
    dayjs(date).format('DD/MM/YYYY') === 'Invalid date'
      ? 'NA'
      : dayjs.utc(date).local().startOf('second').fromNow();
  return newDate;
}

// Current Time
export const getCurrentTimeFormatted = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

// Convert To NormalCase
export function capitalToNormalCase(str) {
  const words = str?.split('_');
  const normalCaseStr = words
    ?.map(function (word) {
      return word?.charAt(0).toUpperCase() + word?.slice(1)?.toLowerCase();
    })
    .join(' ');
  return normalCaseStr;
}

export function camelToNormalCase(str) {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

// Vaccine Color Generator
export function stringToColorHex(input) {
  // let hash = 0;
  // for (let i = 0; i < input.length; i++) {
  //   hash = input.charCodeAt(i) + ((hash << 5) - hash);
  // }

  // let color = '#';
  // for (let i = 0; i < 3; i++) {
  //   const value = (hash >> (i * 8)) & 0xff;
  //   color += ('00' + value.toString(16)).substr(-2);
  // }

  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (((hash >> (i * 8)) & 0xff) % 128) + 127;
    color += ('00' + value.toString(16)).substr(-2);
  }

  return color;
}

export function stringToNiceColor(input) {
  // Create a hash from the input string
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Generate pastel/light colors by constraining the RGB values to a lighter range
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (((hash >> (i * 8)) & 0xff) % 128) + 127; // Ensure values are in the range [127, 255]
    color += ('00' + value.toString(16)).substr(-2);
  }

  return color;
}

export const vaccineIcon = (name) => {
  switch (name) {
    case 'BCG':
      return '#2F79F4';
    case 'HEP-B':
      return '#592B82';
    case 'HPV':
      return '#3CB4BF';
    case 'IPV':
      return '#6C6E2E';
    case 'JE':
      return '#557F37';
    case 'MR':
      return '#3ABCEE';
    case 'OPV':
      return '#034E37';
    case 'PCV':
      return '#003C68';
    case 'ROTA':
      return '#37939B';
    case 'PENTA':
      return '#461497';
    case 'TD':
      return '#3269FF';
    case 'TCV':
      return '#32346F';
    default:
      return stringToColorHex(name?.toLowerCase());
  }
};

// Asset Icons
export const assetIcon = (name) => {
  switch (name) {
    case 'FCW 300':
      return FCW300;
    case 'MF 300':
      return MF300;
    case 'MF 314':
      return MF314;
    case 'MK 144':
      return MK144;
    case 'MK 302':
      return MK302;
    case 'MK 304':
      return MK304;
    case 'MK304':
      return MK304;
    case 'SB 300':
      return SB300;
    case 'TCW 2000 DC':
      return TCW2000DC;
    case 'TCW 3000 AC':
      return TCW3000AC;
    case 'TCW 1152/CF':
      return TCW3000AC;
    case 'TCW 3000 SDD':
      return TCW3000SDD;
    case 'WICR':
      return WICR;
    default:
      return NOIMAGE;
  }
};

export function formatCurrency(value) {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(value);
}

export const createBarsAndLinesConfig = (data) => {
  const keys = Object.keys(data?.[0] || {}).filter((key) => key !== 'date');

  const bars = keys.map((key) => ({
    dataKey: key,
    fill: stringToNiceColor(key),
  }));

  const lines = keys.map((key) => ({
    dataKey: key,
    stroke: stringToNiceColor(key),
  }));

  const chartConfig = keys.reduce((config, key) => {
    config[key] = {
      label: key,
      color: stringToNiceColor(key),
    };
    return config;
  }, {});

  return { bars, lines, chartConfig };
};
