import React, { useEffect, useMemo, useRef, useState } from 'react';
import './DataVisualization.css';

import OrderIcon from '/icons/all/orders/Orders.svg';

import {
  FormControl,
  Select,
  MenuItem,
  Chip,
  Autocomplete,
  TextField,
} from '@mui/material';

import InventoryTable from './dataTables/InventoryTable';
import OrdersTable from './dataTables/OrdersTable';
import ShipmentTable from './dataTables/ShipmentTable';
import LastMileTable from './dataTables/LastMileTable';
import ColdChainTable from './dataTables/ColdChainTable';
import { useTranslation } from 'react-i18next';

import { getOrganizationLevel, getLocations } from '../../../redux/userActions';
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DataGraph from './dataGraph/DataInvGraph';
import DataInvGraph from './dataGraph/DataInvGraph';
import DataColdChainGraph from './dataGraph/DataColdChainGraph';
import DataLastMileGraph from './dataGraph/DataLastMileGraph';
import DataOrderGraph from './dataGraph/DataOrderGraph';
import DataShipmentGraph from './dataGraph/DataShipmentGraph';
import { useReactToPrint } from 'react-to-print';
import Printer from '../../../shared/printer/Printer';

export default function DataVisualization() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState('table');
  const [currentTab, setCurrentTab] = useState('inventory');
  const [searchParams, setSearchParams] = useSearchParams();

  const [levels, setLevels] = useState([]);
  const [locations, setLocations] = useState([]);

  const [viewData, setViewData] = useState();

  useEffect(() => {
    async function fetchData() {
      const resultLevel = await getOrganizationLevel();
      setLevels(resultLevel?.data);
    }
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      orgLevel: searchParams.get('orgLevel') || '',
      locations: [searchParams.get('locations')] || [],
      fromDate: searchParams.get('fromDate') || '',
      toDate: searchParams.get('toDate') || '',
    },
    onSubmit: async (values) => {
      const { fromDate, toDate, orgLevel, locations } = values;

      if (fromDate && toDate) {
        navigate(
          `/user/data-visualization/info?orgLevel=${orgLevel}&locations=${locations}&fromDate=${fromDate}&toDate=${toDate}`
        );
      } else {
        navigate(
          `/user/data-visualization/info?orgLevel=${orgLevel}&locations=${locations}`
        );
      }
    },
  });

  useEffect(() => {
    async function fetchData(level) {
      const res = await getLocations(level);
      setLocations(res?.data);
    }
    fetchData(formik?.values?.orgLevel);
  }, [formik?.values?.orgLevel]);

  const districtOptions = useMemo(
    () =>
      locations.map((district) => ({ id: district._id, name: district.name })),
    [locations]
  );

  const componentRef = useRef();
  const handlePrint = useReactToPrint({ contentRef: componentRef });

  const graphTitle = (tab) => {
    return tab === 'inventory'
      ? t('inventory')
      : tab === 'orders'
      ? t('orders')
      : tab === 'shipments'
      ? t('shipments')
      : tab === 'lastmile'
      ? t('last_mile')
      : tab === 'coldchain'
      ? t('cold_chain')
      : '';
  };

  return (
    <React.Fragment>
      <section className='Page__main_wrapper bg-slate-50'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            {/* <i className="bx bx-box"></i> */}
            <img src={OrderIcon} style={{ height: '22px' }} />
            <h1 className='Page__headline_title_fs'>
              {' '}
              {t('data_visualization')} :{' '}
              <span className='capitalize'>{graphTitle(currentTab)}</span>
            </h1>
          </div>
          <div className='tab__btn_group'>
            <div
              className={`tab__button ${currentView === 'table' && 'active'}`}
              onClick={() => setCurrentView('table')}
            >
              <i class='fa-solid fa-table'></i>
              <h1 className='tab__btn_text_fs'>{t('Table')}</h1>
            </div>

            <div
              className={`tab__button ${currentView === 'graph' && 'active'}`}
              onClick={() => setCurrentView('graph')}
            >
              <i class='fa-solid fa-chart-line'></i>
              <h1 className='tab__btn_text_fs'>{t('Graph')}</h1>
            </div>
          </div>
        </div>

        <div className='Page__action_wrapper'>
          <div className='DataVisualization__container'>
            <form onSubmit={formik.handleSubmit}>
              <div className='dataviz_filters Input__table'>
                <div
                  className={`${
                    currentTab !== 'inventory' && currentTab !== 'coldchain'
                      ? 'Input__row four_long_column'
                      : 'Input__row three_long_column'
                  }`}
                >
                  <div className='Input__column'>
                    <p className='Input__label_fs'>{t('org_level')}</p>
                    <FormControl fullWidth>
                      <Select
                        size='small'
                        className='mui_custom_input_big_field'
                        {...formik.getFieldProps('orgLevel')}
                      >
                        {levels?.map((level, index) => (
                          <MenuItem key={index} value={level?.value}>
                            {level?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {formik.errors.orgLevel && formik.touched.orgLevel && (
                      <div className='Input__error_fs'>
                        {formik.errors.orgLevel}
                      </div>
                    )}
                  </div>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>
                      {t('location')}
                      <b>*</b>{' '}
                    </p>
                    <Autocomplete
                      multiple
                      id='tags-filled'
                      size='small'
                      className='mui_custom_autocomplete'
                      options={districtOptions}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      value={districtOptions.filter((option) =>
                        formik.values.locations.includes(option.id)
                      )}
                      disabled={!formik?.values?.orgLevel}
                      onChange={(event, value) =>
                        formik.setFieldValue(
                          'locations',
                          value.map((option) => option.id)
                        )
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant='outlined'
                            label={option.name}
                            {...getTagProps({ index })}
                            key={option.id}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className='mui_custom_autocomplete'
                          size='small'
                          variant='outlined'
                          placeholder={
                            formik?.values?.locations?.length > 0
                              ? ''
                              : 'Select locations'
                          }
                        />
                      )}
                    />
                    {formik.errors.locations && formik.touched.locations && (
                      <div className='Input__error_fs'>
                        {formik.errors.locations}
                      </div>
                    )}
                  </div>
                  {currentTab !== 'inventory' && currentTab !== 'coldchain' && (
                    <div className='Input__column'>
                      <p className='Input__label_fs'>
                        {t('From / To')}
                        <b>*</b>{' '}
                      </p>
                      <div className='Input__row two_column'>
                        <input
                          {...formik.getFieldProps('fromDate')}
                          type='date'
                          className='mi_custom_textfield date_field_big'
                        />
                        <input
                          {...formik.getFieldProps('toDate')}
                          type='date'
                          className='mi_custom_textfield date_field_big'
                        />
                      </div>
                    </div>
                  )}

                  <button
                    type='submit'
                    className='mi_btn mi_btn_medium mi_btn_primary data_visualization_submit'
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {currentView === 'table' && (
          <div className='Page__table_space'>
            {currentTab === 'inventory' && (
              <InventoryTable
                viewData={viewData}
                tab={currentTab}
                setTab={setCurrentTab}
                type='table'
              />
            )}

            {currentTab === 'orders' && (
              <OrdersTable
                tab={currentTab}
                setTab={setCurrentTab}
                type='table'
              />
            )}

            {currentTab === 'shipments' && (
              <ShipmentTable
                tab={currentTab}
                setTab={setCurrentTab}
                type='table'
              />
            )}

            {currentTab === 'lastmile' && (
              <LastMileTable
                tab={currentTab}
                setTab={setCurrentTab}
                type='table'
              />
            )}

            {currentTab === 'coldchain' && (
              <ColdChainTable
                tab={currentTab}
                setTab={setCurrentTab}
                type='table'
              />
            )}
          </div>
        )}

        <section className='ReviewVAR__list' ref={componentRef}>
          <Printer title={graphTitle(currentTab)}>
            <div className='printing_body column_flex'>
              {currentView === 'graph' && (
                <div className='Page__table_space'>
                  {currentTab === 'inventory' && (
                    <DataInvGraph
                      handlePrint={handlePrint}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                      type='graph'
                    />
                  )}
                  {currentTab === 'orders' && (
                    <DataOrderGraph
                      handlePrint={handlePrint}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                      type='graph'
                    />
                  )}
                  {currentTab === 'shipments' && (
                    <DataShipmentGraph
                      handlePrint={handlePrint}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                      type='graph'
                    />
                  )}
                  {currentTab === 'coldchain' && (
                    <DataColdChainGraph
                      handlePrint={handlePrint}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                      type='graph'
                    />
                  )}
                  {currentTab === 'lastmile' && (
                    <DataLastMileGraph
                      handlePrint={handlePrint}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                      type='graph'
                    />
                  )}
                </div>
              )}
            </div>
          </Printer>
        </section>
      </section>
    </React.Fragment>
  );
}

function ToggleCard({ title, state, tab, setTab }) {
  return (
    <>
      <div
        className={`ToggleCard__card ${state && tab === state && 'active'}`}
        onClick={() => {
          if (state && setTab) {
            setTab(state);
          }
        }}
      >
        <div className={`AnalyticCard__header`}>
          <h1 className='ToggleCard__title_fs'>{title}</h1>
        </div>
      </div>
    </>
  );
}
