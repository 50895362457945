import * as React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export default function CircularLoader({ open }) {
  return (
    <React.Fragment>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={open}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </React.Fragment>
  );
}
