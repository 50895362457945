import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  Alert,
  Autocomplete,
  Checkbox,
  FormControl,
  TextField,
  CircularProgress,
} from "@mui/material";

import "./NewShipment.css";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import ShipmentForm from "./shipmentForm/ShipmentForm";
import ShipmentTable from "./shipmentTable/ShipmentTable";
import ProductTable from "./shipmentTable/ProductTable";
import FetchProduct from "./fetchProduct/FetchProduct";
import { getInBoundOrders } from "../../../redux/orderActions";
import { getCampaigns, getTransitNo } from "../../../redux/shipmentActions";
import CircularLoader from "../../../shared/Loader/CircularLoader";

const INITIAL_STATE = {
  shipmentCompleted: false,
  errorMessage: "",
  tagShipment: false,
  externalTracking: false,
  transitNo: null,
  campaignID: null,
  orderID: null,
  from: "",
  fromDefault: null,
  to: "",
  toDefault: null,
  shipmentDate: dayjs().format("YYYY-MM-DD"),
  deliveryDate: dayjs().format("YYYY-MM-DD"),
  selectedOrder: "",
  productList: [],
  fromLocation: "",
  toLocation: "",
  selectedProducts: [],
  productsListWOID: null,
  selectedBatchList: [],
  openProductPopup: false,
};

export default function NewShipment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: orderIdParams } = useParams();
  const campaignIdData = location?.state?.campaignId;

  const [state, setState] = useState(INITIAL_STATE);

  const updateState = (updates) =>
    setState((prev) => ({ ...prev, ...updates }));

  const { data: ordersData, isLoading: ordersLoading } = useQuery({
    queryKey: ["orders"],
    queryFn: async () => {
      const res = await getInBoundOrders("UNFULFILLED", 0, 0);
      return res?.success ? res.data.data : [];
    },
  });

  const { data: campaignsData, isLoading: campaignsLoading } = useQuery({
    queryKey: ["campaigns"],
    queryFn: async () => {
      const res = await getCampaigns(true, 0, 0);
      return res?.success ? res.data.data : [];
    },
  });

  const { data: tripList, isLoading: tripListLoading } = useQuery({
    queryKey: ["transitNo"],
    queryFn: () => getTransitNo(),
  });

  useEffect(() => {
    updateState({
      orderID: orderIdParams || state.orderID,
      campaignID: campaignIdData || state.campaignID,
    });
  }, [orderIdParams, campaignIdData]);

  useEffect(() => {
    if (ordersData) {
      const newSelectedOrder = ordersData?.filter(
        (order) => order?._id === state.orderID,
      );
      updateState({ selectedOrder: newSelectedOrder });
    }
  }, [state.orderID, ordersData]);

  useEffect(() => {
    if (state.selectedOrder?.length) {
      const [order] = state.selectedOrder;
      updateState({
        from: order?.destination?._id,
        fromDefault: order?.destination,
        to: order?.source?._id,
        toDefault: order?.source,
        productList: order?.products,
      });
    }
  }, [state.selectedOrder]);

  useEffect(() => {
    if (location?.state?.shipmentData) {
      const shipmentData = location.state.shipmentData;
      const updates = {
        selectedBatchList: shipmentData.products,
        deliveryDate: shipmentData.deliveryDate,
        shipmentDate: shipmentData.shippingDate,
        from: shipmentData.source,
        to: shipmentData.destination,
        fromLocation: location.state.fromLocations,
        toLocation: location.state.toLocations,
        productsListWOID: shipmentData.productsListWOID,
      };
      updateState({
        ...updates,
        orderID: shipmentData.orderId || state.orderID,
        campaignID: shipmentData.campaignId || state.campaignID,
      });
      if (location.state.selectedProducts) {
        updateState({ selectedProducts: location.state.selectedProducts });
      }
    }
  }, [location.state]);

  const handleRemoveProducts = (id) => {
    updateState({
      productsListWOID: state.productsListWOID?.filter(
        (prod) => prod?._id !== id,
      ),
      selectedProducts: state.selectedProducts?.filter(
        (prod) => prod?._id !== id,
      ),
      selectedBatchList: state.selectedBatchList
        ?.flat()
        .filter((product) => product?.productId !== id),
    });
  };

  const handleOpenProductPopup = () => {
    if (state.from) {
      updateState({ openProductPopup: true });
    } else {
      updateState({ errorMessage: t("from_location_error") });
      setTimeout(() => updateState({ errorMessage: "" }), 2000);
    }
  };

  const fetchProductDetails = (id) => {
    if (id && campaignsData) {
      const campaignRes = campaignsData.find(
        (campaign) => campaign?._id === id,
      );
      if (campaignRes) {
        updateState({ productsListWOID: [campaignRes?.productDetails] });
      }
    }
  };

  useEffect(() => {
    fetchProductDetails(state.campaignID);
  }, [state.campaignID, campaignsData]);

  const createShipmentData = {
    ...(state.orderID && { orderId: state.orderID }),
    ...(state.campaignID && { campaignId: state.campaignID }),
    source: state.from,
    destination: state.to,
    products: state.selectedBatchList.flat(),
    shippingDate: state.shipmentDate,
    deliveryDate: state.deliveryDate,
    transitNo: state.transitNo,
    externalTracking: state.externalTracking,
    ...(state.orderID && { productList: state.productList }),
    ...(state.campaignID && { productsListWOID: state.productsListWOID }),
    selectedOrder: state.selectedOrder,
  };

  const handleCreateShipment = (e) => {
    e.preventDefault();
    const missingField = !createShipmentData.source
      ? "From Address"
      : !createShipmentData.destination
      ? "To Address"
      : "Shipping Date";

    if (
      !createShipmentData.source ||
      !createShipmentData.destination ||
      !createShipmentData.shippingDate
    ) {
      updateState({ errorMessage: `${missingField} is Required` });
      setTimeout(() => updateState({ errorMessage: "" }), 2000);
      return;
    }
    navigate("/user/shipments/review-shipments", {
      state: {
        shipmentData: createShipmentData,
        selectedProducts: state.selectedProducts,
        fromLocations: state.fromLocation,
        toLocations: state.toLocation,
      },
    });
  };

  if (ordersLoading || campaignsLoading || tripListLoading) {
    return (
      <div className='flex items-center justify-center h-screen'>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <CircularLoader open={!ordersData?.length} />
      <section className='Page__main_wrapper'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <div className='back_Link' onClick={() => navigate(-1)}>
              <i className='bx bx-arrow-back icon_blue'></i>
            </div>
            <h1 className='Page__headline_title_fs'>
              {t("add_shipment_title")}
            </h1>
          </div>
          <BreadCrumb
            url1='/user/shipments'
            Link1={t("shipment")}
            Link2={t("create_shipment")}
          />
        </div>
        <form className='ModalPopup__form_container'>
          <div className='CreateShipment__grid_layout'>
            <div className='CreateShipment__space'>
              <ShipmentForm
                ordersData={ordersData}
                orderID={state.orderID}
                setOrderID={(id) => updateState({ orderID: id })}
                from={state.from}
                setFrom={(from) => updateState({ from })}
                to={state.to}
                setTo={(to) => updateState({ to })}
                shipmentDate={state.shipmentDate}
                setShipmentDate={(date) => updateState({ shipmentDate: date })}
                deliveryDate={state.deliveryDate}
                setDeliveryDate={(date) => updateState({ deliveryDate: date })}
                fromDefault={state.fromDefault}
                toDefault={state.toDefault}
                campaigns={campaignsData}
                campaignID={state.campaignID}
                setCampaignID={(id) => updateState({ campaignID: id })}
                orderIdParams={orderIdParams}
                selectedOrder={state.selectedOrder}
                setFromLocations={(loc) => updateState({ fromLocation: loc })}
                setToLocations={(loc) => updateState({ toLocation: loc })}
                t={t}
              />
              <div className='Input__row two_column column_center'>
                <div className='Authform__terms_button'>
                  <Checkbox
                    name='external-tracking'
                    sx={{ padding: 0 }}
                    checked={state.externalTracking}
                    onChange={() =>
                      updateState({ externalTracking: !state.externalTracking })
                    }
                  />
                  <p className='page__note_fs mi_font_bold'>
                    {t("nextleaf_msg")}
                  </p>
                </div>
              </div>
              <div className='Input__row two_column column_center'>
                <div className='Authform__terms_button'>
                  <Checkbox
                    disabled={!tripList?.length}
                    name='tag-shipment'
                    sx={{ padding: 0 }}
                    checked={state.tagShipment}
                    onChange={() =>
                      updateState({ tagShipment: !state.tagShipment })
                    }
                  />
                  <p className='page__note_fs mi_font_bold'>
                    {t("tag_existing_shipments")}
                  </p>
                </div>
                {state.tagShipment && (
                  <div className='Input__column'>
                    <FormControl fullWidth>
                      <Autocomplete
                        size='small'
                        className='mui_custom_autocomplete'
                        value={state.transitNo || ""}
                        onChange={(_, newValue) =>
                          updateState({ transitNo: newValue })
                        }
                        options={tripList || []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className='mui_custom_autocomplete'
                            size='small'
                            placeholder={t("transit_number")}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                )}
              </div>
              <div className='CreateShipment__table'>
                <div className='Select__tab_headline'>
                  <h1 className='page__body_heading_fs'>
                    {t("product_list")}:
                  </h1>
                </div>
                {state.orderID ? (
                  <ShipmentTable
                    selectedOrder={state.selectedOrder}
                    selectedBatchList={state.selectedBatchList}
                    setSelectedBatchList={(list) =>
                      updateState({ selectedBatchList: list })
                    }
                    productList={state.productList}
                    setProductList={(list) =>
                      updateState({ productList: list })
                    }
                    setSelectedOrder={(order) =>
                      updateState({ selectedOrder: order })
                    }
                    t={t}
                  />
                ) : (
                  <>
                    <ProductTable
                      products={state.productsListWOID}
                      handleRemoveProducts={handleRemoveProducts}
                      selectedBatchList={state.selectedBatchList}
                      setSelectedBatchList={(list) =>
                        updateState({ selectedBatchList: list })
                      }
                      campaignID={state.campaignID}
                      t={t}
                    />
                    {!state.campaignID && (
                      <div className='ShipProdTable__addmore'>
                        <div
                          className='ShipProdTable__addmore_btn'
                          onClick={handleOpenProductPopup}
                        >
                          <i className='fa-solid fa-plus'></i>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <section className='Create__shipment_action Create__shipment_action_space'>
                {state.errorMessage && (
                  <Alert severity='error'>{state.errorMessage}</Alert>
                )}
                <div className='Page__left_actions'>
                  {state.shipmentCompleted ? (
                    <Link
                      to='/user/shipments'
                      className='mi_btn mi_btn_medium mi_btn_primary'
                    >
                      <span>{t("go_to_shipments")}</span>
                    </Link>
                  ) : (
                    <>
                      <Link
                        to='/user/shipments'
                        className='mi_btn mi_btn_medium mi_btn_outline'
                      >
                        <span>{t("back")}</span>
                      </Link>
                      <button
                        type='submit'
                        onClick={handleCreateShipment}
                        className={`mi_btn mi_btn_medium ${
                          (state.productList?.length ===
                            state.selectedBatchList?.flat()?.length &&
                            state.productList?.length > 0) ||
                          (state.productsListWOID?.length ===
                            state.selectedBatchList?.flat()?.length &&
                            state.productsListWOID?.length > 0)
                            ? "mi_btn_primary"
                            : "mi_btn_disabled"
                        }`}
                        disabled={
                          !(
                            (state.productList?.length ===
                              state.selectedBatchList?.flat()?.length &&
                              state.productList?.length > 0) ||
                            (state.productsListWOID?.length ===
                              state.selectedBatchList?.flat()?.length &&
                              state.productsListWOID?.length > 0)
                          )
                        }
                      >
                        <span>{t("confirm")}</span>
                      </button>
                    </>
                  )}
                </div>
              </section>
            </div>
          </div>
        </form>
      </section>
      <FetchProduct
        keepMounted
        from={state.from}
        open={state.openProductPopup}
        onClose={() => updateState({ openProductPopup: false })}
        productsListWOID={state.productsListWOID}
        setProductsListWOID={(products) =>
          updateState({ productsListWOID: products })
        }
        selectedProducts={state.selectedProducts}
        setSelectedProducts={(products) =>
          updateState({ selectedProducts: products })
        }
        t={t}
      />
    </>
  );
}
