import React from 'react';
import { TrendingUp } from 'lucide-react';
import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';

import { Card, CardContent, CardHeader } from '@/components/ui/card';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28EFF'];

// Reusable component definition
export default function PieGraph({ data, title, height }) {
  return data?.length > 0 ? (
    <Card>
      <CardHeader>
        <section className='w-full flex items-center justify-between'>
          <article className='flex flex-col items-start justify-start'>
            <div className='flex gap-2 font-medium leading-none'>
              {title} <TrendingUp className='h-4 w-4' />
            </div>
          </article>
        </section>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width='100%' height={height}>
          <PieChart>
            <Pie
              data={data}
              cx='50%'
              cy='50%'
              outerRadius={80}
              fill='#8884d8'
              dataKey='count'
              nameKey={'_id'}
              label
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  ) : (
    <Card>
      <CardHeader>
        <div className='w-full flex flex-col items-start justify-start'>
          <div className='flex gap-2 font-medium leading-none'>
            {title} <TrendingUp className='h-4 w-4' />
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className='w-full flex flex-col items-center justify-center'>
          <GraphEmpty />
        </div>
      </CardContent>
    </Card>
  );
}
