import axios from 'axios';
import { API_URL } from '../../config';

// Notification Data Fetching Function
export const getNotificationData = async ({ pageParam = 0 }) => {
  const response = await axios.get(API_URL.notificationUrl, {
    params: { page: pageParam + 1, limit: 10 }, // Assuming page starts at 1 on the API
  });
  return response.data.data;
};

export const updateNotificationMsg = async (id) => {
  return (await axios.get(`${API_URL.notificationUrl}/${id}`)).data;
};

// Chat Data Fetching
export const getChatMessage = async (data) => {
  return (await axios.post(API_URL.chatUrl, data)).data;
};

// Create Ticket
export const createTickets = async (data) => {
  return (await axios.post(`${API_URL.ticketUrl}`, data)).data;
};
