import React from 'react';
import { TrendingUp } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import {
  BarChart,
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  LabelList,
} from 'recharts';
import GraphEmpty from '../../../../../shared/utils/emptyTable/GraphEmpty';
import { calculateDateDifference } from '@/utils/helper';

// Reusable component definition
export default function BarGraph({
  data,
  xAxisKey,
  bars,
  title,
  height,
  chartConfig,
  graphType,
  setGraphType,
}) {
  console.log(data);

  const date = calculateDateDifference(data);

  console.log(date);

  return data?.length > 0 ? (
    <Card>
      <CardHeader>
        <section className='w-full flex items-center justify-between'>
          <article className='flex flex-col items-start justify-start'>
            <div className='flex gap-2 text-base font-medium leading-none'>
              {title} <TrendingUp className='h-4 w-4' />
            </div>
            {date && (
              <div className='leading-none text-xs text-muted-foreground'>
                Showing total records for the last {date}
              </div>
            )}
          </article>
          <article className='flex items-center gap-2'>
            <Select
              value={graphType}
              onValueChange={(value) => setGraphType(value)}
            >
              <SelectTrigger className='w-[125px]'>
                <SelectValue placeholder='Graph Type' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='Bars'>Bar Graph</SelectItem>
                <SelectItem value='Areas'>Area Graph</SelectItem>
                <SelectItem value='Lines'>Line Graph</SelectItem>
              </SelectContent>
            </Select>
          </article>
        </section>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart accessibilityLayer data={data}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey={xAxisKey}
              tickLine={false}
              tickMargin={10}
              axisLine={false}
            />
            <YAxis tickLine={false} tickMargin={10} axisLine={false} />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator='dashed' />}
            />
            <ChartLegend content={<ChartLegendContent />} />

            {bars.map((bar, index) => (
              <Bar key={index} dataKey={bar.dataKey} fill={bar.fill} radius={6}>
                <LabelList
                  dataKey={bar.dataKey}
                  position={'top'}
                  offset={6}
                  className='fill-foreground'
                  fontSize={12}
                  content={({ value, x, y, width }) =>
                    value > 0 ? (
                      <text
                        x={x + width / 2}
                        y={y - 12}
                        fill='currentColor'
                        fontSize={12}
                        textAnchor='middle'
                        dominantBaseline='middle'
                      >
                        {value}
                      </text>
                    ) : null
                  }
                />
              </Bar>
            ))}
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  ) : (
    <Card>
      <CardHeader>
        <div className='w-full flex flex-col items-start justify-start'>
          <div className='flex gap-2 font-medium leading-none'>
            {title} <TrendingUp className='h-4 w-4' />
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className='w-full flex flex-col items-center justify-center'>
          <GraphEmpty />
        </div>
      </CardContent>
    </Card>
  );
}
