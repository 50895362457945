import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createTickets, getChatMessage, updateNotificationMsg } from './api';

export const useUpdateNotificationMsg = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => updateNotificationMsg(id),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

export const useGetChatMessages = () => {
  return useMutation({
    mutationFn: (data) => getChatMessage(data),
  });
};

export const useCreateTickets = () => {
  return useMutation({
    mutationFn: (data) => createTickets(data),
  });
};
