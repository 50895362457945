import React from 'react';
import {
  stockAvailabilityData,
  stockOutData,
  expiredStockData,
  nearingExpiryStockData,
  quarantineStockData,
  disposedStockData,
  rejectedStockData,
  wastedStockData,
  netUtilizationData,
} from '../data';
import StockGraph from './InvGraphs/LineGraph';
import ConfigureTable from '../../../../shared/configureTable/ConfigureTable';
import { FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import LineGraph from './InvGraphs/LineGraph';
import { useSearchParams } from 'react-router-dom';
import { useGetOrdersGraph } from '../../../../services/shipment-services/queries';
import {
  createBarsAndLinesConfig,
  stringToColorHex,
} from '../../../../shared/utils/utils';
import BarGraph from './InvGraphs/BarGraph';
import TabContainer from './containers/TabContainer';
import GraphContainer from './containers/GraphContainer';
import CircularLoader from '@/shared/Loader/CircularLoader';

function DataInvGraph({ handlePrint, currentTab, setCurrentTab, type }) {
  // States
  const [view, setView] = React.useState('grid');
  const [graphType, setGraphType] = React.useState('Bars');
  const [graphHeight, setGraphHeight] = React.useState(400);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const columns = [
    { key: 'stockAvailable', title: 'Stock Availability' },
    { key: 'stockOutCount', title: 'Stock Out Count' },
    { key: 'expiredStock', title: 'Expired Stock' },
    { key: 'nearExpiryStock', title: 'Stock Nearing Expiry' },
    { key: 'quarantineStock', title: 'Quarantine Stock' },
    { key: 'disposedStock', title: 'Disposed Stock' },
    { key: 'rejectedStock', title: 'Rejected Stock' },
    { key: 'wastedStock', title: 'Wasted Stock' },
    { key: 'netUtilization', title: 'Net Utilization' },
  ];

  const initialColumns = {
    stockAvailable: true,
    stockOutCount: true,
    expiredStock: true,
    nearExpiryStock: true,
    quarantineStock: true,
    disposedStock: true,
    rejectedStock: true,
    wastedStock: true,
    netUtilization: true,
  };

  const [selectedColumns, setSelectedColumns] = React.useState(initialColumns);
  const [columnData, setColumnData] = React.useState([]);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const filteredColumns = columns.filter(
    (column) => selectedColumns[column.key]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const apiBody = {
    locations: searchParams.get('locations')?.split(',') || [],
  };

  const {
    data: createdData,
    isLoading,
    isError,
  } = useGetOrdersGraph(apiBody, 'CREATED');

  const InventoryGraphs = [
    {
      key: 'stockAvailable',
      data: createdData?.outbound,
      xAxisKey: 'date',
      title: 'Stock Availability',
      ...createBarsAndLinesConfig(createdData?.outbound),
    },
    {
      key: 'stockOutCount',
      data: createdData?.outbound,
      xAxisKey: 'date',
      title: 'Stock Out Count',
      ...createBarsAndLinesConfig(createdData?.outbound),
    },
    {
      key: 'expiredStock',
      data: createdData?.outbound,
      xAxisKey: 'date',
      title: 'Expired Stock',
      ...createBarsAndLinesConfig(createdData?.outbound),
    },
    {
      key: 'nearExpiryStock',
      data: createdData?.outbound,
      xAxisKey: 'date',
      title: 'Stock Nearing Expiry',
      ...createBarsAndLinesConfig(createdData?.outbound),
    },
    {
      key: 'quarantineStock',
      data: createdData?.outbound,
      xAxisKey: 'date',
      title: 'Quarantine Stock',
      ...createBarsAndLinesConfig(createdData?.outbound),
    },
    {
      key: 'disposedStock',
      data: createdData?.outbound,
      xAxisKey: 'date',
      title: 'Disposed Stock',
      ...createBarsAndLinesConfig(createdData?.outbound),
    },
    {
      key: 'rejectedStock',
      data: createdData?.outbound,
      xAxisKey: 'date',
      title: 'Rejected Stock',
      ...createBarsAndLinesConfig(createdData?.outbound),
    },
    {
      key: 'wastedStock',
      data: createdData?.outbound,
      xAxisKey: 'date',
      title: 'Wasted Stock',
      ...createBarsAndLinesConfig(createdData?.outbound),
    },
    {
      key: 'netUtilization',
      data: createdData?.outbound,
      xAxisKey: 'date',
      title: 'Net Utilization',
      ...createBarsAndLinesConfig(createdData?.outbound),
    },
  ];

  if (isLoading) return <>Loading...</>;

  if (isError) return <div>Error: {isError.message}</div>;
  return (
    <div>
      <TabContainer
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        columnData={columnData}
        setColumnData={setColumnData}
        columns={columns}
        selectedColumns={selectedColumns}
        handleColumnToggle={handleColumnToggle}
        handleResetColumns={handleResetColumns}
        handleClick={handleClick}
        handlePrint={handlePrint}
        title={'Filter the Graph'}
        view={view}
        setView={setView}
        graphHeight={graphHeight}
        setGraphHeight={setGraphHeight}
        type={type}
      />

      <div className={`Graph_list ${view}`}>
        {InventoryGraphs.map((graph) =>
          filteredColumns.some((col) => col.key === graph.key) ? (
            <GraphContainer graph={graph} graphHeight={graphHeight} />
          ) : null
        )}
      </div>
    </div>
  );
}

export default DataInvGraph;
