import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import backupIcon from '/defaults/profile_default.jpg';
import { useUpdateUserInfo } from '../../../../services/user-services/mutations';
import { uploadImage } from '../../../../redux/imageAction';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { API_URL } from '../../../../config';

export default function UpdatePersonalInfo({ user, onClose }) {
  const { theToken } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [preview, setPreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const { mutate } = useUpdateUserInfo();
  const queryClient = useQueryClient();

  const imageUrl = user?.photoId
    ? `${API_URL.imagesUrl}/${user?.photoId}`
    : backupIcon;

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      photoId: '',
    },
    onSubmit: async (values) => {
      if (
        values.firstName !== user?.firstName ||
        values.lastName !== user?.lastName ||
        preview
      ) {
        let photoId = user?.photoId;
        if (preview) {
          photoId = await uploadCampaignImage(imageFile);
        }

        const data = {
          firstName: values.firstName,
          lastName: values.lastName,
          photoId: photoId,
        };
        mutate(data, {
          onSuccess: () => {
            queryClient.invalidateQueries('userInfo');
            toast.success(t('Personal Info Updated'));
            setImageFile(null);
            setPreview(null);
            onClose();
          },
        });
      } else {
        setImageFile(null);
        setPreview(null);
        onClose();
      }
    },
  });

  const uploadCampaignImage = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      try {
        const imageKey = await uploadImage(formData, theToken);
        if (imageKey?.data) {
          return imageKey?.data;
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        toast.error('Image upload error');
      }
    }
  };

  return (
    <div className='ModalPopup__form_container'>
      <div className='ModalPopup__wrapper'>
        <form>
          <div className='ModalPopup__body'>
            <div className='ModalPopup__form_section'>
              <div className='ModalPopup__label_header'>
                <h1 className='Input__label_fs'>{t('update_user_subtitle')}</h1>
              </div>
              <div className='Input__table'>
                <div className='Input__column'>
                  <div className='UploadProfile_wrapper'>
                    <div className='UploadProfile_preview'>
                      {
                        <img
                          src={preview ? preview : imageUrl}
                          alt='Profile Preview'
                        />
                      }
                    </div>
                    <div className='UploadProfile_info'>
                      <p className='Input__label_fs'>Upload New Profile</p>
                      <input
                        type='file'
                        accept='image/*'
                        onChange={handleFileChange}
                      />
                      <span className='Input__label_fs'>
                        The maximum file size allowed is 200 KB.
                      </span>
                    </div>
                  </div>
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('first_name')}
                    <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    id='firstName'
                    name='firstName'
                    maxLength={50}
                    {...formik.getFieldProps('firstName')}
                  />
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('last_name')}
                    <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    id='lastName'
                    name='lastName'
                    maxLength={50}
                    {...formik.getFieldProps('lastName')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='UpdateProfile__form_action'>
            <button
              className='mi_btn mi_btn_medium mi_btn_primary'
              onClick={formik.handleSubmit}
            >
              <span>Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
